/* Provide sufficient contrast against white background */
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.showBullet li {
 list-style-type: circle;
}

.hideBullet {
    list-style-type: none !important;
}

.hidden {
    display: none;
}


code {
    color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-dropdown{
    width: 100%;
    border: 1px solid #00000042;
    background-color: #FFF;
}

#amazonbuttondiv {
    text-align: center;
    display: block;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.btn-amz {
    color: #111;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    border-color: #111;
    box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    font-size: 1.1rem;
}

    .btn-amz:hover {
        border-color: #a88734 #9c7e31 #846a29;
    }


.btn-amzNew {
    color: #111;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    border-color: #111;
    box-shadow: 0 1px 0 rgba(255,255,255,.4) inset;
    font-size: 12px;
}

.btn-amzNew:hover {
    border-color: #a88734 #9c7e31 #846a29;
}

.btn-haeblue {
    color: #fff;
    border-color: #1861ac;
    background-color: #0036A9 !important;
    margin-top: 15px !important;
}

.btn-haeblue:hover { 
    color: #fff !important;
    background-color: #042979 !important;
}

body > #root {
    height: 100%;
}

html,
body {
    background-color: #F6F6F6;
    height: 100%;
    font-family: "Questrial",sans-serif;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
    font-family: "Lato",sans-serif;
}

#wrapper {
    position: relative;
    min-height: 100%;
    background-color: #F6F6F6
}

.hsb {
    background-color: #FFF;

}

.displayes {
    display: none;
    background-color: #f6f6f6;
}

#content {
    margin-top: 135px;
    padding-top: 2rem;
    padding-bottom: 30rem !important;
}

.fixed{
    position: fixed;
    right:0;
    left:0;
    z-index:1030;
    background-color: #F6F6F6;
}

.bluetitle {
    color: #006E61;
    font-weight: 700;
    padding-left: 5%;
    margin-bottom: 10px;
}

.modal-title {
    color: #006E61;
    font-weight: 700;
}

#title-search > div {
    width: unset;
}

.filter-bar {
    /*padding-top: .7%;*/
    padding-right: 4%;
    text-align: right;
}

select > option.hidden-option {
    display: none;
}

select{
    font-size: 16px !important;
}

input {
    font-size: 16px !important;
}

.donate-items{
    min-height:83vh;
}

iframe {
    border-radius: 4px;
    height: 1650px;
    width: 100%;
}

a.navbar-brand{
    text-align: unset;
}

.statebutton {
    width: 100% !important;
    min-height: calc(1.5em + 0.75rem + 1px) !important;
    border: 0px !important;
}

#pl{
    text-align: center;
    padding-left: 2%;
}

#pl > div {
    margin: 0 16px !important;
    padding: 0px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    /*border-radius: 14px;
    vertical-align: top;
    box-shadow: 6px 4px 13px 3px #00000042;
    background: #FFFFFF;
    float: none;
    margin: 16px 0;
    position: relative;
    text-align: center;*/
    }

    #pl > div:hover {
        transform: scale(1.1);
    }

#pl > div > div {
    padding: 7px;
    border-radius: 8px;
    vertical-align: top;
    box-shadow: 6px 4px 13px 3px #00000042;
    background: #FFFFFF;
    float: none;
    margin: 16px 0;
    position: relative;
    text-align: center;
}

.sppic {
    margin: 5px;
    min-width: 20%;
    max-width: 96%;
    height: 60px;
    border-radius: 10px;
}

.pic {
    margin: 12px;
    max-height: 200px;
    /*height: 200px;*/
    height: 125px;
    min-height: 125px;
    max-width: 125px;
    border-radius: 12px;
}

#pl > div > div > .sp {
    border-bottom: 1px solid #dee2e6 !important
}

#pl > div > div > .title {
    font-size: 1rem;
    padding: 0 2px 0;
}

#pl > div > div > .price {
/*    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #03A9F4;
    box-shadow: -2px 3px 12px 5px rgba(0,0,0,.5);
    color: white;
    padding: .3rem;
    margin: .3rem;
    border-radius: 8px;
    text-shadow: 0 0 5px gray;*/
    font-weight: bold;
    margin-top: 5px;
}

.donatebartext{
    font-size: 12px;
    margin: .5rem 0 2px;
}

.progress {
    box-shadow: inset 1px 1px 5px 1px #00000042;
}

.itemdonateprogress {
    margin: 0 6px 10px;
}

.address{
    margin: 1rem 0 1rem 1rem;
}

.bg-haublue {
    background-color: #006E61 !important;
    box-shadow: 1px 1px 5px 1px #00000042;
}

#cta{
    margin: 35px 0px;
    text-align: center;
}

.footerrow {
    background: #404041;
    padding: 40px 0 6%;
    color: #ffffff;
    margin-top: 40px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

    .footerrow a:hover {
        color: #c8c8c8;
        text-decoration: none;
    }

    .footerrow a {
        color: #ffffff;
        font-size: 20px;
    }

    .footerrow ul {
        list-style: none;
        margin-left: 1px;
        padding-left: 1px;
    }

    .footerrow ul li {
        padding-bottom: 18px;
    }

.col-xs-5ths, .col-sm-5ths, .col-md-5ths, .col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-md-5ths {
    width: 20%;
    float: left;
}

.innerfootercolumn {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.img-responsive {
    margin: 0 auto;
    padding-bottom: 30px;
    width: 100%;
}

.text-nowrap {
    white-space: nowrap !important;
}

.innerfootercolumnsmall {
    display: none !important;
}

.bottom-buffer {
    margin-bottom: 40px;
}

.top-buffer {
    margin-top: 40px;
}

#footer {
    width: 100%;
    height: 95px;
    bottom: 0;
    left: 0;
    text-align: center;
    /*    border-top-color: #B5C0CB;*/
    font-size: .7rem;
    font-family: "Questrial", sans-serif;
    position: absolute;
    padding-left: 40px;
    padding-right: 40px;
}


#footer .nav_last {
    color: #fff;
    text-align: center;
    display: inline;
    font-size: 1.25em !important;
}

.nav_last:hover {
    color: #fff;
    font-size: 1.25em !important;
}

.mobile-bottom-nav{
    display: none;
}

@media (min-width: 2560px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 100%;
    }

    .bluetitle {
        padding-left: 15%;
        margin-bottom: 0px;
    }

    /*#pl{
        padding-left: 6%;
    }*/

    .sppic {
        margin: 5px !important;
        min-width: 20% !important;
        max-width: 96% !important;
        height: 80px !important;
        border-radius: 10px !important;
    }

    .pic {
        margin: 20px 12px 12px;
        max-height: 250px;
        height: 225px;
        min-height: 200px;
        max-width: 250px;
        border-radius: 12px;
        align-content: center;
    }

    #pl > div > div > .title , .price {
        text-align: center;
        font-size: 1.25rem;
    }

}

@media (max-width: 1440px) {
    #pl {
        padding-left: 2% !important;
    }

    .pic {
        margin: 20px 12px 12px;
        max-height: 200px;
        height: 125px;
        min-height: 100px;
        border-radius: 12px;
        align-content: center;
    }
}

@media (max-width: 1366px) {    
    .nav-img{
        width: 25%;
    }

    /*#content{
        padding-bottom: 94px;
    }*/

    .mobile-bottom-nav {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1600;
        transform: translateZ(0);
        display: flex;
        height: 40px;
        box-shadow: 0 -2px 5px -2px #333;
        background-color: #F6F6F6;
    }

    .item{
        flex-grow: 1;
        text-align: center;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .item--active {
        /*//dev color:red;*/
    }

    .item-content {
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

        .item-content > a {
            color: black;
            cursor: pointer;
        }
}

@media (max-width: 1024px) {
    #pl {
        padding-left: 10% !important;
    }
}

@media (max-width: 992px) {
    .bluetitle {
        padding-left: 0;
    }

    #pl {
        padding-left: 10%;
    }

    .pic {
        margin: 20px 10px 10px;
        max-height: 200px;
        height: 145px;
        min-height: 130px;
        max-width: 145px;
        border-radius: 12px;
        align-content: center;
    }

}

@media (max-width: 896px){
    .pic {
        margin: 10px 10px 10px;
        max-height: 200px;
        height: 100px;
        min-height: 100px;
        max-width: 125px;
        border-radius: 12px;
        align-content: center;
    }

    .title{
        font-size: .9rem !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }
}

@media (max-width: 812px) {
    .pic {
        margin: 20px 10px 10px;
        max-height: 200px;
        height: 135px;
        min-height: 130px;
        max-width: 130px;
        border-radius: 12px;
        align-content: center;
    }

    #content {
        margin-top: 64px;
    }

    .title {
        font-size: 1rem !important;
    }

    iframe {
        border-radius: 4px;
        height: 1900px;
        width: 100%;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }
}

@media (max-width: 768px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 46%;
    }

    .sort-bar, .filter-bar {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .filter-bar {
        padding-left: 3px;
    }

    #title-search, #pl {
        padding-left: 2.5% !important;
    }

    #pl > div {
        margin: 0 10px !important;
        padding: 0px;
    }

    .sppic {
        margin: 0px;
        min-width: 20%;
        max-width: 96%;
        height: 80px;
        border-radius: 10px;
    }

    .pic {
        margin: 20px 12px 12px;
        max-height: 200px;
        height: 185px;
        min-height: 150px;
        max-width: 192px;
        border-radius: 12px;
        align-content: center;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }
}

@media (max-width: 510px) {
    .navbar-brand {
        margin-right: 0px !important;
        flex: 0 0 75% !important;
        max-width: 75% !important;
    }

    .nav-item {
        text-align: right !important;
    }

    .sort-bar, .filter-bar {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .sort-bar {
        padding-right: 7.5px;
    }

    .filter-bar {
        padding-left: 7.5px;
    }

    #title-search, #pl {
        padding-left: 0% !important;
    }

    #pl > div {
        margin: 0 0 0 10px !important;
    }

    .sppic {
        margin: 0px;
        min-width: 20%;
        max-width: 96%;
        height: 60px;
        border-radius: 10px;
    }

    .pic {
        margin: 20px 12px 12px;
        max-height: 200px;
        height: 125px;
        min-height: 120px;
        max-width: 125px;
        border-radius: 12px;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    .footerrow {
        padding: 40px 0 180px !important;
    }

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }

    .innerfootercolumn {
        display: none;
    }

    #footer {
        padding-bottom: 11rem !important;
    }
}


@media (max-width: 375px) {
    .navbar-brand {
        margin-right: 0px !important;
        flex: 0 0 75% !important;
        max-width: 75% !important;
    }

    .nav-item {
        text-align: right !important;
    }

    .sort-bar, .filter-bar{
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .sort-bar{
        padding-right: 7.5px;
    }
    .filter-bar{
        padding-left:7.5px;
    }

    .col-xs-6 {
        flex: 0 0 50%;
        max-width: 45%;
    }

    #pl {
        padding-left: 0% !important;
    }

    #pl > div {
        margin: 0 0 0 12px !important;
    }

    .sppic {
        margin: 0px;
        min-width: 20%;
        max-width: 96%;
        height: 50px;
        border-radius: 10px;
    }

    .pic {
        margin: 20px 12px 12px;
        max-height: 112px;
        height: 100px;
        max-width: 100px;
        min-height: 100px;
        border-radius: 12px;
        align-content: center;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 50px;
    }

    .footerrow {
        padding: 40px 0 190px !important;
    }

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }

    .innerfootercolumn {
        display: none;
    }

    #footer {
        padding-bottom: 12rem !important;
    }
}

@media (max-width: 320px) {
    #pl {
        padding-left: 0% !important;
    }

    #pl > div {
        margin: 0 0 0 12px !important;
    }

    .col-xs-6 {
        flex: 0 0 50%;
        max-width: 44%;
    }

    .bluetitle {
        font-size: 1.7rem;
    }

    .sppic {
        margin: 0px;
        min-width: 20%;
        max-width: 96%;
        height: 50px;
        border-radius: 10px;
    }

    .pic {
        margin: 20px 12px 12px;
        max-height: 112px;
        height: 85px;
        min-height: 85px;
        max-width: 97px;
        border-radius: 12px;
        align-content: center;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 40px;
    }

    .footerrow {
        padding: 40px 0 200px !important;
    }

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }

    .innerfootercolumn {
        display: none;
    }

    #footer {
        padding-bottom: 12rem !important;
    }
}

.myBlue {
    color: #0036A9 !important;
}

.item-content a {
    font-size: 1.2em;
}

.brsmall {
    display: block;
    margin-bottom: .2rem;
}

.svg-inline--fa.fa-fw {
    margin-bottom: -.4rem !important;
}

.hsb {
    display: block;
}

/*iphone XR, 11 PORTRAIT*/
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 60px;
        padding-top: 1rem;
        padding-bottom: 30px;
    }

    .innerfootercolumn{
        display: none;
    }

/*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }
}

/*iphone XR, 11 LANDSCAPE*/
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) and (orientation : landscape) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 65px;
        padding-top: 1.5rem;
        padding-bottom: 30px;
    }

    .innerfootercolumn {
        display: none;
    }

    /*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }
}

/*iphone XS Max, 11 Pro Max PORTRAIT*/
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 60px;
        padding-top: 1rem;
        padding-bottom: 30px;
    }

    .innerfootercolumn {
        display: none;
    }

/*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }
}

/*iphone XS Max, 11 Pro Max LANDSCAPE*/
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 65px;
        padding-top: 1.5rem;
        padding-bottom: 30px;
    }

    .innerfootercolumn {
        display: none;
    }

    /*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }
}


/*iPhone X  PORTRAIT css*/
@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 65px;
        padding-top: .5rem;
        padding-bottom: 0px;
    }

    .innerfootercolumn {
        display: none;
    }

/*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    .footerrow {
        padding: 40px 0 190px !important;
    }

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 80px;
    }

    #footer {
        padding-bottom: 12rem !important;
    }
}

/*iPhone X LANDSCAPE*/
@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }


    .innerfootercolumn {
        display: none !important;
    }

    /*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    #wrapper > .footerrow {
        padding: 40px 0 150px !important;
    }

    .footerfirstcolumn {
        text-align: center !important;
        width: 100% !important;
        padding: 0 250px !important;
    }

    #footer {
        padding-bottom: 9rem !important;
    }
}


/*iPhone 6, 7, 8 Plus css*/
@media only screen and (min-device-width : 414px) and (max-device-height : 736px) and (-webkit-device-pixel-ratio : 3) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    .innerfootercolumn {
        display: none;
    }

/*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    .footerrow {
        padding: 40px 0 180px !important;
    }

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }

    #footer {
        padding-bottom: 11rem !important;
    }
}


/*iPhone 6, 7, 8 Plus LANDSCAPE css*/
@media only screen and (min-device-width : 414px) and (max-device-height : 736px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content{
        margin-top: 50px;
    }

    .innerfootercolumn {
        display: none !important;
    }

    /*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    #wrapper > .footerrow {
        padding: 40px 0 140px !important;
    }

    .footerfirstcolumn {
        text-align: center !important;
        width: 100% !important;
        padding: 0 180px !important;
    }

    #footer {
        padding-bottom: 8rem !important;
    }
}

/*iPhone 6, 7 & 8 PORTRAIT css*/
@media only screen and (min-device-width : 375px) and (max-device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 45px;
    }

    .innerfootercolumn {
        display: none;
    }
/*
    .innerfootercolumnsmall {
        display: block!important;
    }*/

    #wrapper > .footerrow {
        padding: 40px 0 185px !important;
    }

    .footerfirstcolumn {
        text-align: center;
        width: 100%;
        padding: 0 85px;
    }

    #footer {
        padding-bottom: 12rem !important;
    }
}

/*iPhone 6, 7 & 8 LANDSCAPE css*/
@media only screen and (min-device-width : 375px) and (max-device-height : 667px) and (-webkit-device-pixel-ratio : 2) and (orientation : landscape) {
    #navbartog {
        display: block !important;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }


    .innerfootercolumn {
        display: none !important;
    }

    /*    .innerfootercolumnsmall {
        display: block !important;
    }*/

    #wrapper > .footerrow {
        padding: 40px 0 160px !important;
    }

    .footerfirstcolumn {
        text-align: center !important;
        width: 100% !important;
        padding: 0 180px !important;
    }

    #footer {
        padding-bottom: 9rem !important;
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    #navbartog{
        display: block;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    .innerfootercolumn {
        display: block;
    }

    .footerfirstcolumn {
        text-align: left;
        width: 20%;
        padding: 0;
    }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    #navbartog {
        display: block;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 3rem;
    }

    .footerrow {
        padding: 40px 0 110px !important;
    }

    .footerrow > #footer {
        padding-bottom: 7.5rem !important;
    }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    #navbartog {
        display: block;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 4rem;
    }

    .footerrow {
        padding: 40px 0 110px !important;
    }

    .innerfootercolumn {
        display: block;
    }

    .footerfirstcolumn {
        text-align: center;
        width: 20% !important;
        padding-right: 15px;
        padding-left: 15px;
    }

    .footerrow > #footer {
        padding-bottom: 7rem !important;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    #navbartog {
        display: block;
    }

    .hsb {
        display: none !important;
    }

    .displayes {
        display: block;
    }

    #content {
        margin-top: 4.5rem;
    }

    .footerrow {
        padding: 40px 0 120px !important;
    }

    .footerrow > #footer {
        padding-bottom: 8rem !important;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

}

.titleregister {
    display: block; text-align: center; margin-bottom: 25px; font-size: 18px; font-weight: bold;
}

.flexregister {
    display: flex;
}

.registerbuttonmodal {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    width: 50%;
    height: 50px; 
    border-right: 1px solid black;
}

.registerbuttonmodal2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 50px;
}


.rbuttonmodal {
   width: 9rem;
}

.rmargin {
    margin-right: 5px;
}

.nobar {

    border-bottom: none !important;
}

.mymodalregister {
    width: 95.5%;
    pointer-events: auto;
    background-clip: padding-box;
    border-radius: .3rem;
    outline: 0;
}

.modal-body {
    padding-bottom: 50px;
}

.modal-dialog {
    margin: 1.75rem auto;
}

.hovercursor:hover {
    cursor: pointer;
}

@media print {
    .hide-on-print {
        display: none;
    }
}



