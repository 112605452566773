a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.navbar-brand{
    padding: 0px;
    margin: 0px;
}

.myContainerHeader {
    max-width: 100%;
    padding: 0px !important;
    height: 135px;
}

.nav-img {
    width: 30%;
}

.nav-img2 {
    width: 225px;
}

.navbarMax {
    max-width: calc(100% - 300px);
}

.newLogo {
    position: relative;
    top: 20px;
    left: 50px;
}

.socialicons {
     padding-top: 13px; 
}

    .socialicons > span > a {
        color: #0033a0;
    }

.svg-inline--fa.fa-stack-1x {
    height: 1.2em;
    width: 1.25em;
}

.svg-inline--fa.fa-stack-2x {
    height: 1.5em;
    width: 1.1em;
}

.fa-stack{
    padding: 0 23px;
}

.socialwhite {
    color: #ffffff;
    z-index: 1;
}

.socialred {
    color: #0033a0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.nav-container {
    position: relative;
}

.secondarynav {
    position: absolute;
    right: 0;
    top: 0;
    color: #000;
    width: 100%;
    text-align: center;
    background-color: #006e61;
    height: 38px !important;
}

.NeedHelp {
    padding-top: 10px;
    font-size: 14px;
    color: #fff;
    -webkit-font-smoothing: antialiased;
}

.btn-donate {
    background-image: none;
    color: #fff;
    background-color: #006E61;
    border-color: #fff;
    border-radius: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 1.1em;
    font-family: "Lato",sans-serif;
    padding: 12px 40px;
    min-width: 250px;
}

    .btn-donate:hover {
        color: #ffffff;
        background-color: #006E61;
        border-color: #fff;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

li {
    list-style-type: none;
}

#bignav {
    margin-top: 50px;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.9);
}

.dropdown-menu {
    /*    background-color: #F6F6F6 !important;
    text-align: right !important;*/
    background-color:#fff;
    font-size: 1em;
    color: black;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0px;
}

    .dropdown-menu > div > a {
        margin: 0px;
        color: black !important;
        font-size: 15px;
        padding: 5px 3px !important;
        line-height: 20px;
    }

    .dropdown-menu > div {
        padding: 2px 8px 0px;
    }

    .cd-panel__content >  li > a {
        margin: 0px;
        font-size: 1rem;
    }

#storydropdown {
}

.dropdown-item:hover, .dropdown-item:focus {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0) !important;
}

.nav-link {
    font-size: 17px;
    padding-bottom: 10px;
    font-weight: bolder;
    color: black !important;
    padding-right: 20px !important;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
/*    color: #0036a9 !important;*/
/*    transform: scale(1.05);*/
}

.sn-button {
    border: none;
    display: inline-block;
    padding: 15px 20px 15px 20px !important;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
}

    .sn-button:hover {
        text-decoration: none;
    }


.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

#mySidebar{
    z-index: 1500;
}

/* -------------------------------- 
Slide In Panel - by CodyHouse.co
-------------------------------- */
.cd-main-content {
    text-align: center;
}

    .cd-main-content h1 {
        font-size: 2rem;
        color: #64788c;
        padding: 4em 0;
    }

.cd-btn {
    position: relative;
    display: inline-block;
    padding: 1em 2em;
    background-color: #89ba2c;
    color: #ffffff;
    border-radius: 50em;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
    -webkit-transition: -webkit-box-shadow 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

    .cd-btn:hover {
        -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
    }

@media only screen and (min-width: 1170px) {
    .cd-main-content h1 {
        font-size: 3.2rem;
    }
}

.cd-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;
}

    .cd-panel::after {
        /* overlay layer */
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        cursor: pointer;
        -webkit-transition: background 0.3s 0.3s;
        transition: background 0.3s 0.3s;
    }

    .cd-panel.cd-panel--is-visible {
        visibility: visible;
        -webkit-transition: visibility 0s 0s;
        transition: visibility 0s 0s;
    }

        .cd-panel.cd-panel--is-visible::after {
            background: rgba(0, 0, 0, 0.6);
            -webkit-transition: background 0.3s 0s;
            transition: background 0.3s 0s;
        }

.cd-panel__header {
    position: fixed;
    width: 70%;
/*    height: 50px;*/
    line-height: 50px;
    background: rgba(255, 255, 255, 0.96);
    z-index: 2;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    -webkit-transition: -webkit-transform 0.3s 0s;
    transition: -webkit-transform 0.3s 0s;
    transition: transform 0.3s 0s;
    transition: transform 0.3s 0s, -webkit-transform 0.3s 0s;
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
}

    .cd-panel__header h1 {
        color: #006E61;
        padding-left: 5%;
    }

    .cd-panel__header h2 {
        color: #006E61;
        padding-left: 10%;
        margin-top: .5rem;
    }

    .cd-panel__header h3 {
        color: #006E61;
        padding-left: 5%;
        margin-top: .5rem;
    }

.cd-panel--from-right .cd-panel__header {
    right: 0;
}

.cd-panel--from-left .cd-panel__header {
    left: 0;
}

.cd-panel--is-visible .cd-panel__header {
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

@media only screen and (min-width: 768px) {
    .cd-panel__header {
        width: 70%;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-panel__header {
        width: 50%;
    }
}

.cd-panel__close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 60px;
    /* image replacement */
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

    .cd-panel__close::before, .cd-panel__close::after {
        /* close icon created in CSS */
        content: '';
        position: absolute;
        top: 22px;
        left: 20px;
        height: 3px;
        width: 20px;
        background-color: #424f5c;
        /* this fixes a bug where pseudo elements are slighty off position */
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .cd-panel__close::before {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .cd-panel__close::after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .cd-panel__close:hover {
        background-color: #424f5c;
    }

        .cd-panel__close:hover::before, .cd-panel__close:hover::after {
            background-color: #ffffff;
            -webkit-transition: -webkit-transform 0.3s;
            transition: -webkit-transform 0.3s;
            transition: transform 0.3s;
            transition: transform 0.3s, -webkit-transform 0.3s;
        }

        .cd-panel__close:hover::before {
            -webkit-transform: rotate(220deg);
            -ms-transform: rotate(220deg);
            transform: rotate(220deg);
        }

        .cd-panel__close:hover::after {
            -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
        }

.cd-panel--is-visible .cd-panel__close::before {
    -webkit-animation: cd-close-1 0.6s 0.3s;
    animation: cd-close-1 0.6s 0.3s;
}

.cd-panel--is-visible .cd-panel__close::after {
    -webkit-animation: cd-close-2 0.6s 0.3s;
    animation: cd-close-2 0.6s 0.3s;
}

@-webkit-keyframes cd-close-1 {
    0%, 50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@keyframes cd-close-1 {
    0%, 50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@-webkit-keyframes cd-close-2 {
    0%, 50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

@keyframes cd-close-2 {
    0%, 50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

.cd-panel__container {
    position: fixed;
    width: 70%;
    height: 100%;
    top: 0;
    background: #F6F6F6;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
}

.cd-panel--from-right .cd-panel__container {
    right: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.cd-panel--from-left .cd-panel__container {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.cd-panel--is-visible .cd-panel__container {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

@media only screen and (min-width: 768px) {
    .cd-panel__container {
        width: 70%;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-panel__container {
        width: 50%;
    }
}

.cd-panel__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px 5%;
    overflow: auto;
    /* smooth scrolling on touch devices */
    -webkit-overflow-scrolling: touch;
}

    .cd-panel__content p {
        font-size: 1.4rem;
        color: #424f5c;
        line-height: 1.4;
        margin: 2em 0;
    }

        .cd-panel__content p:first-of-type {
            margin-top: 0;
        }

@media only screen and (min-width: 768px) {
    .cd-panel__content p {
        font-size: 1.6rem;
        line-height: 1.6;
    }
}



@media (min-width: 576px){
    .navbar-expand-sm .navbar-toggler {
        display: unset !important;
    }
}
