.itmreq{
    padding-right: 0px;
}

.splogo {
    min-width: 20%;
    max-width: 96%;
    height: 60px;
    margin-top: -10px;
}

.newitembtn {
    text-align: right;
    padding: 0px;
}

table {
    background-color: white;
    border: 1px solid #B5C0CB !important;
    box-shadow: 4px 4px 4px #E0E0E0 !important;
    border-radius: 15px;
    border-spacing: 0 !important;
    border-collapse: separate !important;
}

table td {
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;
    border-left: none !important;
    padding: 45px 20px !important;
    text-align: center;
}

.center{
    text-align: center;
}

#itemwidth > div > div > .pic {
    margin: 0px !important;
    max-height: 100px !important; 
    height: 60px!important;
    min-height: 50px !important; 
    max-width: 85px !important;
    border-radius: 12px !important;
}

#itemwidth > div > div {
    padding-right: 0px !important;
}


#itemwidth{
    /* width: 28%;
    padding: 30px 30px !important; */
}

#pricewidth{
    width: 1%;
}

#amountfullwidth{
    width: 25%;
}

#daterequestedwidth{
    width: 15%;
}

#actionwidth{
    width: 15%;
}

table th {
    border: none !important;
    padding: 15px 30px 15px 30px !important;
    background-color: #B5C0CB;
    text-align: center;
}

center {
    text-align: center;
}

table tr:first-child th:first-child {
    border-top-left-radius: 13px;
}

table tr:first-child th:last-child {
    border-top-right-radius: 13px;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 13px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 13px;
}

.blue-title {
    color: #0036A9;
    font-weight: bold;
    margin-bottom: 25px;
}

.margin-right {
    margin-right: 15px;
}

.center-content {
    margin-top: -19px !important;
}

.center-buttons {
    margin-top: -7px !important;
}

.align-right {
    text-align: right;
}

.table {
    display: table;
}

.table-cell {
    display: table-cell;
}

.statebutton {
    width: 100% !important;
    min-height: calc(1.5em + 0.75rem + 1px) !important;
    border: 0px !important;
}

.deleteModalFooter {
    margin-top: 10px;
}

.modalFooter {
    margin-top: 7px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff0;
    border-color: #dee2e6 #dee2e6 #F6F6F6;
    font-weight: bold;
    padding: .5rem 10px;
}

.nav-tabs > li > .nav-link {
    margin-top: 0px;
    padding: 0.5rem 10px !important;
    font-size: 1rem;
}

.nav-tabs {
    margin-bottom: 25px;
    cursor: pointer;
}

/*.new-item-button {
    text-align: right;
    width: 100%;
    padding-right: 15px;
}*/

.btn-primary {
    color: #fff;
    background-color: #006E61 !important;
    border-color: #0036A9; /*set the color you want here*/
}

    .btn-primary:hover, .btn-primary:focus {
        color: #fff;
        background-color: #00b3db;
        border-color: #285e8e; /*set the color you want here*/
    }

@media (max-width: 375px) {
    .hsb {
        display: none !important;
    }

}

@media (max-width: 320px) {

    .hsb {
        display: none !important;
    }

}

/*iphone XR, 11*/
@media only screen and (min-device-width : 414px) and (max-device-width : 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio : 2) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table td {
            padding: 10px !important;
            border-left: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
        }

    table {
        background-color: #ffffff00 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    tr {
        background-color: white;
        border: 1px solid #B5C0CB !important;
        box-shadow: 4px 4px 4px #E0E0E0 !important;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 200px;
        margin-left: 150px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 0px !important;
    }
     
    td:before {
        position: absolute;
        top: 12px;
        left: 6px;
        width: 200px;
        padding-right: 40px;
        white-space: nowrap;
        margin-left: -150px;
    }

    td.SP:nth-of-type(1):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    td.SP:nth-of-type(2):before {
        content: "Price";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px !important;
    }

    td.SP:nth-of-type(3):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: .6rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(4):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(5):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(2):before {
        content: "Address";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px;
    }

    td.SP1:nth-of-type(3):before {
        content: "Action";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.AD1:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.AD1:nth-of-type(3):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD1:nth-of-type(4):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD2:nth-of-type(1):before {
        content: "Name";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(3):before {
        content: "Quantity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(4):before {
        content: "Date Bought";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(5):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: -5px;
        font-weight: bold;
    }

    td:nth-of-type(1):before {
        content: "Order Number";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 5.5rem;
        font-weight: bold;
    }

    td:nth-of-type(3):before {
        content: "Charity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(4):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Order Total";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Purchase Date";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(6):before {
        content: "Receipt";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }
    td.SP1:nth-of-type(1):before {
        content: "test1";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.SP1:nth-of-type(2):before {
        content: "test2";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.SP1:nth-of-type(3):before {
        content: "test3";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    #itemwidth {
        width: auto;
    }

    #pricewidth {
        width: auto;
    }

    #amountfullwidth {
        width: auto;
    }

    #daterequestedwidth {
        width: auto;
    }

    #actionwidth {
        width: auto;
    }

    .center-content {
        margin-top: -9px !important;
    }

    .align-right {
        text-align: center;
    }

    .hsb {
        display: none;
    }
}

/*iphone XS Max, 11 Pro Max*/
@media only screen and (min-device-width : 414px) and (max-device-width : 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio : 3) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table td {
            padding: 10px !important;
            border-left: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
        }

    table {
        background-color: #ffffff00 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    tr {
        background-color: white;
        border: 1px solid #B5C0CB !important;
        box-shadow: 4px 4px 4px #E0E0E0 !important;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 200px;
        margin-left: 150px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 0px !important;
    }

    td:before {
        position: absolute;
        top: 12px;
        left: 6px;
        width: 200px;
        padding-right: 40px;
        white-space: nowrap;
        margin-left: -150px;
    }

    td.SP:nth-of-type(1):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    td.SP:nth-of-type(2):before {
        content: "Price";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px !important;
    }

    td.SP:nth-of-type(3):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: .6rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(4):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(5):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(2):before {
        content: "Address";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px;
    }

    td.SP1:nth-of-type(3):before {
        content: "Action";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.AD1:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.AD1:nth-of-type(3):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD1:nth-of-type(4):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD2:nth-of-type(1):before {
        content: "Name";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(3):before {
        content: "Quantity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(4):before {
        content: "Date Bought";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(5):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: -5px;
        font-weight: bold;
    }


    td:nth-of-type(1):before {
        content: "Order Number";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 5.5rem;
        font-weight: bold;
    }

    td:nth-of-type(3):before {
        content: "Charity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(4):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Order Total";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Purchase Date";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(6):before {
        content: "Receipt";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    #itemwidth {
        width: auto;
    }

    #pricewidth {
        width: auto;
    }

    #amountfullwidth {
        width: auto;
    }

    #daterequestedwidth {
        width: auto;
    }

    #actionwidth {
        width: auto;
    }

    .center-content {
        margin-top: -9px !important;
    }

    .align-right {
        text-align: center;
    }

    .new-item-button {
        padding-right: 0px;
    }

    .hsb {
        display: none;
    }
}

/*iPhone X css*/
@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio : 3) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table td {
            padding: 10px !important;
            border-left: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
        }

    table {
        background-color: #ffffff00 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    tr {
        background-color: white;
        border: 1px solid #B5C0CB !important;
        box-shadow: 4px 4px 4px #E0E0E0 !important;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 200px;
        margin-left: 150px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 0px !important;
    }

    td:before {
        position: absolute;
        top: 12px;
        left: 6px;
        width: 200px;
        padding-right: 40px;
        white-space: nowrap;
        margin-left: -150px;
    }

    td.SP:nth-of-type(1):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    td.SP:nth-of-type(2):before {
        content: "Price";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px !important;
    }

    td.SP:nth-of-type(3):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: .6rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(4):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(5):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(2):before {
        content: "Address";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px;
    }

    td.SP1:nth-of-type(3):before {
        content: "Action";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.AD1:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.AD1:nth-of-type(3):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD1:nth-of-type(4):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD2:nth-of-type(1):before {
        content: "Name";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(3):before {
        content: "Quantity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(4):before {
        content: "Date Bought";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(5):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: -5px;
        font-weight: bold;
    }


    td:nth-of-type(1):before {
        content: "Order Number";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 5.5rem;
        font-weight: bold;
    }

    td:nth-of-type(3):before {
        content: "Charity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(4):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Order Total";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Purchase Date";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(6):before {
        content: "Receipt";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    #itemwidth {
        width: auto;
    }

    #pricewidth {
        width: auto;
    }

    #amountfullwidth {
        width: auto;
    }

    #daterequestedwidth {
        width: auto;
    }

    #actionwidth {
        width: auto;
    }

    .center-content {
        margin-top: -9px !important;
    }

    .align-right {
        text-align: center;
    }

    .new-item-button {
        padding-right: 0px;
    }

    .hsb {
        display: none;
    }
}

/*iPhone 6, 7, 8 Plus css*/
@media only screen and (min-device-width : 414px) and (max-device-height : 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio : 3) {

    table, thead, tbody, th, td, tr {
        display: block;
    }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table td {
            padding: 10px !important;
            border-left: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
        }

    table {
        background-color: #ffffff00 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    tr {
        background-color: white;
        border: 1px solid #B5C0CB !important;
        box-shadow: 4px 4px 4px #E0E0E0 !important;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 200px;
        margin-left: 150px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 0px !important;
    }

    td:before {
        position: absolute;
        top: 12px;
        left: 6px;
        width: 200px;
        padding-right: 40px;
        white-space: nowrap;
        margin-left: -150px;
    }

    td.SP:nth-of-type(1):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    td.SP:nth-of-type(2):before {
        content: "Price";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px !important;
    }

    td.SP:nth-of-type(3):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: .6rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(4):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(5):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(2):before {
        content: "Address";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px;
    }

    td.SP1:nth-of-type(3):before {
        content: "Action";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.AD1:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.AD1:nth-of-type(3):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(1):before {
        content: "Name";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(3):before {
        content: "Quantity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(4):before {
        content: "Date Bought";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(5):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: -5px;
        font-weight: bold;
    }


    td:nth-of-type(1):before {
        content: "Order Number";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 5.5rem;
        font-weight: bold;
    }

    td:nth-of-type(3):before {
        content: "Charity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(4):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Order Total";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Purchase Date";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(6):before {
        content: "Receipt";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    #itemwidth {
        width: auto;
    }

    #pricewidth {
        width: auto;
    }

    #amountfullwidth {
        width: auto;
    }

    #daterequestedwidth {
        width: auto;
    }

    #actionwidth {
        width: auto;
    }

    .center-content {
        margin-top: -9px !important;
    }

    .align-right {
        text-align: center;
    }

    .hsb {
        display: none;
    }
}

/*iPhone 6, 7 & 8 css*/
@media only screen and (min-device-width : 375px) and (max-device-height : 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio : 2) {

    table, thead, tbody, th, td, tr {
        display: block;
    }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table td {
            padding: 10px !important;
            border-left: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
        }

    table {
        background-color: #ffffff00 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    tr {
        background-color: white;
        border: 1px solid #B5C0CB !important;
        box-shadow: 4px 4px 4px #E0E0E0 !important;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 200px;
        margin-left: 150px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 0px !important;
    }

    td:before {
        position: absolute;
        top: 12px;
        left: 6px;
        width: 200px;
        padding-right: 40px;
        white-space: nowrap;
        margin-left: -150px;
    }

    td.SP:nth-of-type(1):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    td.SP:nth-of-type(2):before {
        content: "Price";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px !important;
    }

    td.SP:nth-of-type(3):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: .6rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(4):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(5):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(2):before {
        content: "Address";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px;
    }

    td.SP1:nth-of-type(3):before {
        content: "Action";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.AD1:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.AD1:nth-of-type(3):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD1:nth-of-type(4):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD2:nth-of-type(1):before {
        content: "Name";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(3):before {
        content: "Quantity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(4):before {
        content: "Date Bought";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(5):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: -5px;
        font-weight: bold;
    }


    td:nth-of-type(1):before {
        content: "Order Number";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 5.5rem;
        font-weight: bold;
    }

    td:nth-of-type(3):before {
        content: "Charity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(4):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Order Total";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Purchase Date";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(6):before {
        content: "Receipt";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    #itemwidth {
        width: auto;
    }

    #pricewidth {
        width: auto;
    }

    #amountfullwidth {
        width: auto;
    }

    #daterequestedwidth {
        width: auto;
    }

    #actionwidth {
        width: auto;
    }

    .center-content {
        margin-top: -9px !important;
    }

    .align-right {
        text-align: center;
    }

    .hsb {
        display: none;
    }
}

/*mobile css*/
@media only screen and (min-device-width : 375px) and (max-device-height : 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio : 2) {

    table, thead, tbody, th, td, tr {
        display: block;
    }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table td {
            padding: 10px !important;
            border-left: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
        }

    table {
        background-color: #ffffff00 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    tr {
        background-color: white;
        border: 1px solid #B5C0CB !important;
        box-shadow: 4px 4px 4px #E0E0E0 !important;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 200px;
        margin-left: 150px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 0px !important;
    }

    td:before {
        position: absolute;
        top: 12px;
        left: 6px;
        width: 200px;
        padding-right: 40px;
        white-space: nowrap;
        margin-left: -150px;
    }

    td.SP:nth-of-type(1):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    td.SP:nth-of-type(2):before {
        content: "Price";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px !important;
    }

    td.SP:nth-of-type(3):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: .6rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(4):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(5):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(2):before {
        content: "Address";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px;
    }

    td.SP1:nth-of-type(3):before {
        content: "Action";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.AD1:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.AD1:nth-of-type(3):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD1:nth-of-type(4):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD2:nth-of-type(1):before {
        content: "Name";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(3):before {
        content: "Quantity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(4):before {
        content: "Date Bought";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(5):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: -5px;
        font-weight: bold;
    }


    td:nth-of-type(1):before {
        content: "Order Number";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 5.5rem;
        font-weight: bold;
    }

    td:nth-of-type(3):before {
        content: "Charity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(4):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Order Total";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Purchase Date";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(6):before {
        content: "Receipt";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    #itemwidth {
        width: auto;
    }

    #pricewidth {
        width: auto;
    }

    #amountfullwidth {
        width: auto;
    }

    #daterequestedwidth {
        width: auto;
    }

    #actionwidth {
        width: auto;
    }

    .center-content {
        margin-top: -9px !important;
    }

    .align-right {
        text-align: center;
    }

    .hsb {
        display: none;
    }
}

/*iPhone 5 css*/
@media only screen and (min-device-width : 320px) and (max-device-height : 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio : 2) {

    table, thead, tbody, th, td, tr {
        display: block;
    }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table td {
            padding: 10px !important;
            border-left: 1px solid #eee !important;
            border-bottom: 1px solid #eee !important;
        }

    table {
        background-color: #ffffff00 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    tr {
        background-color: white;
        border: 1px solid #B5C0CB !important;
        box-shadow: 4px 4px 4px #E0E0E0 !important;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 200px;
        margin-left: 150px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 0px !important;
    }

    td:before {
        position: absolute;
        top: 12px;
        left: 6px;
        width: 200px;
        padding-right: 40px;
        white-space: nowrap;
        margin-left: -150px;
    }

    td.SP:nth-of-type(1):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    td.SP:nth-of-type(2):before {
        content: "Price";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px !important;
    }

    td.SP:nth-of-type(3):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: .6rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(4):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP:nth-of-type(5):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.SP1:nth-of-type(2):before {
        content: "Address";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 0px;
    }

    td.SP1:nth-of-type(3):before {
        content: "Action";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD1:nth-of-type(1):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem + 50px);
    }

    td.AD1:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: 50px !important;
    }

    td.AD1:nth-of-type(3):before {
        content: "Date Requested";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD1:nth-of-type(4):before {
        content: "Actions";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td.AD2:nth-of-type(1):before {
        content: "Name";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(3):before {
        content: "Quantity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
        padding-top: calc(1rem - 10px);
    }

    td.AD2:nth-of-type(4):before {
        content: "Date Bought";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 0px;
        font-weight: bold;
    }

    td.AD2:nth-of-type(5):before {
        content: "Service Provider";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: -5px;
        font-weight: bold;
    }

    td:nth-of-type(1):before {
        content: "Order Number";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(2):before {
        content: "Item";
        text-align: left;
        padding-left: 1.5rem;
        padding-top: 5.5rem;
        font-weight: bold;
    }

    td:nth-of-type(3):before {
        content: "Charity";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(4):before {
        content: "Amount Fulfilled";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Order Total";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(5):before {
        content: "Purchase Date";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    td:nth-of-type(6):before {
        content: "Receipt";
        text-align: left;
        padding-left: 1.5rem;
        font-weight: bold;
    }

    #itemwidth {
        width: auto;
    }

    #pricewidth {
        width: auto;
    }

    #amountfullwidth {
        width: auto;
    }

    #daterequestedwidth {
        width: auto;
    }

    #actionwidth {
        width: auto;
    }

    .center-content {
        margin-top: -9px !important;
    }

    .align-right {
        text-align: center;
    }

    .hsb {
        display: none;
    }
}

/*iPhone LANDSCAPE*/
/*iphone XR, 11*/
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) and (orientation : landscape) {

    .hsb {
        display: none;
    }
}

/*iphone XS Max, 11 Pro Max*/
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {

    .hsb {
        display: none;
    }
}

/*iPhone X css*/
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {

    .hsb {
        display: none;
    }
}

/*iPhone 6, 7, 8 Plus css*/
@media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {

    .hsb {
        display: none;
    }
}

/*iPhone 6, 7 & 8 css*/
@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) and (orientation : landscape) {

    .hsb {
        display: none;
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .hsb {
        display: none;
    }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

    .hsb {
        display: none;
    }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

    .hsb {
        display: none;
    }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

    .hsb {
        display: none;
    }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

    .hsb {
        display: none;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .hsb {
        display: none;
    }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .hsb {
        display: none;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .hsb {
        display: none;
    }
}

.amazonimg {
    padding-left: 25px;
    max-width: 160px;
}